import React, { useRef } from 'react';

const TrendingNFTs = () => {
	const sliderRef = useRef<HTMLDivElement>(null);

	const handleOnSlide = (direction: number) => {
		if (sliderRef.current) {
			const sliderList = sliderRef.current;
			const scrollAmount = sliderList.clientWidth * direction;
			sliderList.scrollBy({ behavior: 'smooth', left: scrollAmount });
		}
	};

	const slides = [
		<img
			src='Card-1.png'
			key='card-1'
			// className='w-[360px] h-[450]'
		/>,
		<img
			src='Card-2.png'
			key='card-2'
			// className='w-[360px] h-[450]'
		/>,
		<img
			src='Card-3.png'
			key='card-3'
			// className='w-[360px] h-[450]'
		/>,
		<img
			src='Card-1.png'
			key='card-1'
			// className='w-[360px] h-[450]'
		/>,
		<img
			src='Card-2.png'
			key='card-2'
			// className='w-[360px] h-[450]'
		/>
	];

	return (
		<div className='w-full flex flex-col items-center md:p-20 sm:p-16 p-12 text-white mt-4'>
			<p className='font-monument text-center text-[32px] sm:text-[36px] md:text-[40px]'>Trending NFTs</p>

			<div className='w-[100vw] max-w-full relative'>
				<div
					className='overflow-x-auto [&::-webkit-scrollbar]:hidden'
					ref={sliderRef}
				>
					<div
						className='mt-12 slider-list'
						style={{ display: 'grid', gap: '36px', gridTemplateColumns: `repeat(${slides.length},1fr)` }}
					>
						<button
							onClick={() => handleOnSlide(1)}
							className='flex items-center justify-center bg-[#363338] sm:w-[75px] sm:h-[75px] w-[50px] h-[50px] rounded-full drop-shadow-[0px_6px_6.7px_rgba(0,0,0,0.57)] absolute -right-8 top-[50%] -translate-y-1/2 z-10'
						>
							<img
								src='Expand_right.png'
								className='sm:h-[32px] sm:w-[32px] h-[24px] w-[24px]'
							/>
						</button>
						{slides.map((item, i) => (
							<div
								className='md:w-[360px] md:h-[450px] sm:w-[300px] sm:h-[400px] w-[250px] h-[340px]'
								key={i}
							>
								{item}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TrendingNFTs;
