import React from 'react';

const Campaigns = () => {
	return (
		<div className='w-full flex flex-col items-center md:p-20 sm:p-16 p-12 text-white mt-4'>
			<p className='font-monument text-center text-[32px] sm:text-[36px] md:text-[40px] md:w-[70%] w-full'>
				Participate in Quests and Campaigns
			</p>
			<p className='text-center text-[18px] sm:text-[20px] md:text-[24px] mb-10'>
				If you&apos;re a project, join the waitlist to host a campaign
			</p>
			<button className='mb-12 rounded-[14px] sm:rounded-[16px] md:rounded-[18px] border-1 border-solid border-[#B4B4B4] text-[#B4B4B4] font-monument flex items-center justify-center p-2 sm:p-4 text-[16px] sm:text-[18px] md:text-[20px]'>
				Submit Form
			</button>
			<img
				src='challenge-1.png'
				className='scale-90'
			/>
			<p className='text-[#E4E4E4] flex items-center flex-wrap text-[14px] sm:text-[20px] md:text-[24px] mt-2'>
				Past Challenges: <span className='font-semibold'>Blast Lift Off, Avail light client lift-off</span>
			</p>
		</div>
	);
};

export default Campaigns;
