import React from 'react';

const HeroCard = ({ imageSrc, title, desc }: { imageSrc: string; title: string; desc: string }) => {
	return (
		<div className='md:rounded-[25px] rounded-[20px] md:py-4 md:px-5 p-2 bg-[rgba(255,255,255,0.08)] border border-solid border-[rgba(255,255,255,0.08)] flex items-center gap-x-2'>
			<img
				src={imageSrc}
				className='md:h-[70px] md:w-[70px] sm:h-[50px] sm:w-[50px] h-[40px] w-[40px]'
			/>
			<div className='text-center'>
				<p className='text-white font-superclarendon md:text-[36px] text-[30p]'>{title}</p>
				<p className='text-grey-5 md:text-[16px] text-[12px]'>{desc}</p>
			</div>
		</div>
	);
};

export default HeroCard;
