import './styles.css';
import React, { ReactNode } from 'react';
import Footer from './Footer';
import NavHeader from './NavHeader';

const AppLayout = ({ children }: { children: ReactNode }) => {
	return (
		<div className='font-jost max-w-[2000px] mx-auto'>
			<NavHeader />
			<div className='main-content min-h bg-transparent mb-12'>{children}</div>
			<Footer />
		</div>
	);
};

export default AppLayout;
