import React from 'react';

const Marketplace = () => {
	return (
		<div className='w-full flex flex-col items-center md:p-20 sm:p-16 p-12 text-white mt-4'>
			<p className='font-monument text-center text-[32px] sm:text-[36px] md:text-[40px]'>Explore Marketplace</p>
			<p className='text-center text-[16px] sm:text-[20px] md:text-[24px] mb-10'>
				Trade inscriptions and NFTs across networks
			</p>
			<a
				href='http://app.blobscriptions.xyz/'
				target='_blank'
				className='mb-12 rounded-[14px] sm:rounded-[16px] md:rounded-[18px] border-1 border-solid border-[#B4B4B4] text-[#B4B4B4] font-monument flex items-center justify-center p-2 sm:p-4 text-[16px] sm:text-[18px] md:text-[20px]'
				rel='noreferrer'
			>
				Open Marketplace
			</a>
			<img src='marketplace.png' />
		</div>
	);
};

export default Marketplace;
