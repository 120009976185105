import React from 'react';

const WhatDoWeDo = () => {
	return (
		<div className='w-full flex flex-col items-center md:p-20 sm:p-16 p-12 text-white mt-4'>
			<p className='font-monument font-extrabold text-center md:text-[65px] sm:text-[50px] text-[40px]'>
				What do we do?
			</p>
			<p className='text-center sm:w-[60%] w-full md:text-[24px] sm:text-[18px] text-[14px]'>
				Blobscriptions is a cross-chain coordination layer expanding inscription capabilities across ecosystems. We
				optimise inscriptions to minimise network congestion
			</p>
			<img
				src='desktop.png'
				// className='2xl:w-[70%] w-full 2xl:h-[1000px] h-[800px] mt-8'
				className='scale-90 mt-8'
			/>
		</div>
	);
};

export default WhatDoWeDo;
