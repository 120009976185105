import React from 'react';

const Leaderboard = () => {
	return (
		<div className='w-full flex flex-col items-center md:p-20 sm:p-16 p-12 text-white mt-4'>
			<p className='font-monument text-center text-[32px] sm:text-[36px] md:text-[40px]'>Leaderboard</p>
			<p className='text-center text-[16px] sm:text-[20px] md:text-[24px] mb-10 md:w-[70%] w-full'>
				Join loyalty campaigns on Blobscriptions and earn xp. Top members receive Blob squad NFTs and some exciting
				rewards
			</p>
			<img
				src='leaderboard.png'
				className='scale-90'
			/>
		</div>
	);
};

export default Leaderboard;
