import React from 'react';
import './App.css';
import HeroSection from './components/HeroSection';
import { BannerSection } from './components/BannerSection';
import WhatDoWeDo from './components/WhatWeDo';
import TrendingNFTs from './components/TrendingNFTs';
import Partners from './components/Partners';
import Marketplace from './components/Marketplace';
import Campaigns from './components/Campaigns';
import Leaderboard from './components/Leaderboard';

function App() {
	return (
		<div className='font-jost'>
			<HeroSection />
			<BannerSection />
			<WhatDoWeDo />
			<TrendingNFTs />
			<Partners />
			<Marketplace />
			<Campaigns />
			<Leaderboard />
		</div>
	);
}

export default App;
