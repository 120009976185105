import React from 'react';
import HeroCard from '../../ui-components/HeroCard';

const HeroSection = () => {
	return (
		<div className='w-full flex flex-col items-center md:p-20 sm:p-16 p-12 text-white'>
			<p className='text-center font-extrabold md:text-[65px] sm:text-[50px] text-[40px] font-monument flex items-center flex-col xl:flex-row'>
				World&apos;s First{' '}
				<img
					src='hero-font.png'
					className='md:h-[140px] md:w-[620px] sm:h-[120px] sm:w-[580px] h-[100px] w-[540px]'
				/>
			</p>
			<p className='font-extrabold md:text-[65px] sm:text-[50px] text-[40px] font-monument text-center'>
				layer for Inscription
			</p>
			<div className='sm:w-[70%] w-full mt-2 flex items-center'>
				<p className='sm:w-[90%] w-full md:text-[24px] sm:text-[18px] text-[14px] text-center'>
					Seamlessly create and trade digital assets like inscriptions and NFTs across various ecosystems.
				</p>
				<img
					className='sm:block hidden h-[100px] w-[100px]'
					src='Badge.png'
				/>
			</div>
			<div className='flex justify-center items-center flex-wrap w-full gap-8 mt-6'>
				<HeroCard
					imageSrc='users.png'
					title='1M+'
					desc='Users'
				/>
				<HeroCard
					imageSrc='star.png'
					title='700K+'
					desc='Inscriptions Minted'
				/>
				<HeroCard
					imageSrc='lightning-bolt.png'
					title='~700k'
					desc='Inscription NFTs Claimed'
				/>
			</div>
		</div>
	);
};

export default HeroSection;
