import { Accordion, AccordionItem } from '@nextui-org/accordion';
import { Input } from '@nextui-org/input';
import React from 'react';

const Footer = () => {
	const faqs = [
		{
			answer: 'Yes, any network can launch their marketplace by reaching out to us and filling the partner form',
			question: 'Can anyone launch a campaign?'
		},
		{
			answer: 'Write to us at blobscriptions@gmail.com',
			question: 'how to add/request inscription support for my network?'
		},
		{
			answer: 'Anyone with a wallet can list their NFTs',
			question: 'Who can list NFTs?'
		},
		{
			answer:
				'The Blob squad NFT is a non-transferrable membership NFT to be airdropped to active users. We will share more updates regarding this soon',
			question: 'Is blob squad NFT transferrable?'
		}
	];
	return (
		<div className='footer sm:px-16 px-8 py-12 bg-[#18181A]'>
			<div className='flex flex-col gap-y-[48px]'>
				<div>
					<p className='text-white font-monument sm:text-[40px] text-[32px] sm:mb-12 mb-8'>
						Frequently Asked Questions
					</p>
					<Accordion>
						{faqs.map((item) => (
							<AccordionItem
								key={item.question}
								aria-label={item.question}
								indicator={({ isOpen }) =>
									isOpen ? (
										<span className='font-medium text-primary text-[24px]'>-</span>
									) : (
										<span className='font-medium text-white text-[24px]'>+</span>
									)
								}
								title={<span className='sm:text-[24px] text-[18px] text-primary font-medium'>{item.question}</span>}
							>
								{item.answer}
							</AccordionItem>
						))}
					</Accordion>
				</div>
				<div className='bg-primary rounded-[38px] px-12 py-16 flex justify-between md:flex-row flex-col gap-x-4 gap-y-8'>
					<div>
						<p className='text-white font-monument text-[24px]'>Contact Us</p>
						<p className='text-white text-[18px]'>
							Questions, bugs reports, feedback, feature requests- we are here for it all!
						</p>
					</div>
					<div className='relative h-full'>
						<Input
							placeholder='Enter your email'
							size='lg'
							className='md:w-[400px] w-full'
							// onChange={(e) => setFormData({ ...formData, name: e.target.value })}
							// disabled={loading}
						/>
						<button className='font-medium text-black bg-white rounded-md py-1 px-3 absolute right-3 top-[50%] -translate-y-1/2'>
							Submit
						</button>
					</div>
				</div>
				<div className='flex items-center justify-between sm:flex-row flex-col gap-y-6'>
					<div className='sm:w-[50%] w-full flex items-center h-full'>
						<img
							src='blobscriptions-logo.png'
							alt='blobscriptions-logo'
							className='sm:w-[240px] sm:h-[60px] w-[210px] h-[50px]'
						/>
					</div>
					<div className='sm:w-[50%] w-full flex justify-between'>
						<div className='flex flex-col gap-y-3'>
							<span className='text-grey-1 text-lg mb-2'>Company</span>
							<span className='text-grey-2 text-sm'>About us</span>
							<span className='text-grey-2 text-sm'>Features</span>
							<span className='text-grey-2 text-sm'>Partners</span>
							<span className='text-grey-2 text-sm'>Careers</span>
						</div>
						<div className='flex flex-col gap-y-3'>
							<span className='text-grey-1 text-lg mb-2'>Contact Us</span>
							<span className='text-grey-2 text-sm'>Online Chat</span>
							<a
								href='https://x.com/blobscriptions'
								target='_blank'
								rel='noreferrer'
								className='text-grey-2 text-sm'
							>
								Twitter
							</a>
							<a
								href='https://t.me/blobscriptions'
								target='_blank'
								rel='noreferrer'
								className='text-grey-2 text-sm'
							>
								Telegram
							</a>
							<span className='text-grey-2 text-sm'>Discord</span>
						</div>
						<div className='flex flex-col gap-y-3'>
							<span className='text-grey-1 text-lg mb-2'>Resources</span>
							<span className='text-grey-2 text-sm'>FAQs</span>
							<span className='text-grey-2 text-sm'>Articles</span>
							<span className='text-grey-2 text-sm'>Whitepaper</span>
							<span className='text-grey-2 text-sm'>Blog</span>
						</div>
					</div>
				</div>
				<div className='border-t border-grey-4 border-solid flex items-center justify-between flex-col sm:flex-row py-4 gap-y-4'>
					<div className='flex items-center sm:gap-8 gap-4 flex-wrap text-grey-3 text-sm'>
						<span>About us</span>
						<span>Contact</span>
						<span>Privacy policy</span>
						<span>Sitemap</span>
						<span>Terms of Use</span>
					</div>
					<div className='text-grey-3 text-sm'>© Copyright 2024, All Rights Reserved</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
